<template>
  <div class="InterviewManagement">
    <div style="display:flex;">
      <div
        :style="{
          width: '230px',
          'min-width': '230px',
          height: clientH,
          'border-right': '1px solid #efefef'
        }"
        :autoshow="true"
      >
        <!-- <a-tree :treeData="treeData"  @select="onSelect" :selectedKeys='departmentSel'></a-tree> -->
        <div v-for="(item, index) of treeData" :key="index">
          <div
            @click="selectFn(item)"
            :class="{
              'tree-li': true,
              'border-r': id == item.id,
              selected: id == item.id,
              'border-r-rgb': id != item.id
            }"
            :style="{ 'text-indent': item.children ? '0px' : '24px' }"
          >
            <a-icon
              :type="arSel.indexOf(item.id) > 0 ? 'caret-down' : 'caret-right'"
              @click.stop="openclick(item)"
              v-if="item.children"
              style="margin-right: 6px;"
            />{{ item.title }}
          </div>
          <div
            v-if="item.children && arSel.indexOf(item.id) > 0"
            v-for="(list, k) in item.children"
            :key="k"
            @click="selectFn(list)"
            :class="{
              'tree-li': true,
              'border-r': id == list.id,
              selected: id == list.id,
              'border-r-rgb': id != list.id
            }"
            style="text-indent: 40px;"
          >
            {{ list.title }}
          </div>
        </div>
      </div>
      <div class="InterviewManagement-content">
        <div class="HRcensus">
          <a-form style="float:right;" layout="inline">
            <a-form-item>
              <a-month-picker
                size="small"
                style="width: 100px"
                @change="monthChange"
                :defaultValue="moment(censusMonth, monthFormat)"
                :format="monthFormat"
                placeholder="月份"
              />
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 100px"
                size="small"
                v-model="censusHR"
                placeholder="需求HR"
              >
                <!-- <a-select-option v-for="item in needs_type_arr" value="jack">Jack</a-select-option> -->
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 100px"
                size="small"
                v-model="censusArea"
                placeholder="大区"
              >
                <!--<a-select-option v-for="item in progress_type_arr" value="jack">Jack</a-select-option> -->
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 100px"
                size="small"
                v-model="censusCity"
                placeholder="城市"
              >
                <!--<a-select-option v-for="item in progress_type_arr" value="jack">Jack</a-select-option> -->
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" size="small">确 定</a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-layout style="clear: both;margin: 20px 0px 14px;">
          <a-layout-sider
            style="background-color:#ffffff;border-right:1px solid #eee;"
          >
            <div style="width:120px;margin:0px auto;text-align:center;">
              <div
                class="border step-circle"
                style="width: 86px;height:86px;line-height: 82px;"
              >
                {{ '120人' }}
              </div>
              <div style="margin-top: 10px;">成功招聘人数</div>
            </div>
          </a-layout-sider>
          <a-layout-content style="background-color:#ffffff;">
            <div class="step-box">
              <div class="liner-div"></div>
              <div class="step-item" v-for="(list, k) in HRarr" :key="k">
                <span class="border step-circle"></span>
                <div class="step-title">{{ list.name }}</div>
                <span>({{ list.value }}份)</span>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
        <div></div>
        <div class="serach_box">
          <a-form layout="inline" style="width:80%;">
            <a-form-item>
              <a-tree-select
                style="min-width: 220px;max-width:360px"
                :treeData="posts_type_arr"
                :value="posts_type"
                @change="onChangeTree"
                treeNodeFilterProp="title"
                treeCheckable
                :showCheckedStrategy="SHOW_PARENT"
                placeholder="请选择岗位"
              />
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 120px"
                v-model="needs_type"
                placeholder="需求HR"
              >
                <!-- <a-select-option v-for="item in needs_type_arr" value="jack">Jack</a-select-option> -->
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                style="width: 120px"
                v-model="progress_type"
                placeholder="简历来源"
              >
                <!--<a-select-option v-for="item in progress_type_arr" value="jack">Jack</a-select-option> -->
                <a-select-option value="jack">Jack</a-select-option>
                <a-select-option value="lucy">Lucy</a-select-option>
                <a-select-option value="Yiminghe">yiminghe</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-range-picker
                style="width:220px;"
                format="YYYY-MM-DD"
                :placeholder="['开始时间', '结束时间']"
                allowClear
                @change="changeTime"
              />
              <!-- <a-select style="width: 120px" v-model="pressing_type" placeholder="紧急程度">
                            <a-select-option value="jack">Jack</a-select-option>
                            <a-select-option value="lucy">Lucy</a-select-option>
                            <a-select-option value="Yiminghe">yiminghe</a-select-option>
                        </a-select> -->
            </a-form-item>
            <a-form-item>
              <a-input
                placeholder="请输入"
                style="width:200px;"
                v-model="classify_name"
              />
            </a-form-item>
            <a-form-item>
              <a-button style="margin-right:20px" @click="resetBtn()"
                >重置</a-button
              >
              <a-button type="primary" @click="serachBtn()">查询</a-button>
            </a-form-item>
          </a-form>
          <a-dropdown class="map-position ant-menu-item-selected">
            <a class="ant-dropdown-link" href="#">
              <a-icon
                type="login"
                style="font-size:18px;vertical-align: middle;"
              />
              添加简历
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="CVctrlFn('import')">导入简历</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="CVctrlFn('add')">添加简历</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <a-table
          :columns="columns"
          :dataSource="data"
          :pagination="false"
          :customRow="rowClick"
          :scroll="{ x: '150%' }"
          style="margin-top:10px;border:1px solid #efefef"
        >
          <template slot="action" slot-scope="columnsInfo">
            <a-tooltip placement="left" overlayClassName="tooltipBg">
              <!-- trigger="click" -->
              <template slot="title">
                <div style="padding: 0px 4px;">
                  <p class="hoverColor" @click="stopFn(columnsInfo)">
                    停止招聘{{ columnsInfo.key }}
                  </p>
                  <p
                    class="hoverColor"
                    @click="delFn(columnsInfo)"
                    style="margin-top:2px;"
                  >
                    删除{{ columnsInfo.age }}
                  </p>
                </div>
              </template>
              <a-icon
                type="ellipsis"
                style="font-size: 28px;font-weight: 800;"
              />
            </a-tooltip>
          </template>
          <template slot="footer" slot-scope="currentPageData">
            <div style="font-weight:bold;">
              <span style="margin-right:20px;"> 合计:</span> {{ '36' }}
            </div>
          </template>
        </a-table>
        <div style="margin: 20px 0;float:right">
          <a-pagination
            showQuickJumper
            :defaultCurrent="1"
            :total="total"
            :pageSize="page"
            @change="pageChange"
          />
        </div>
      </div>
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>

    <a-drawer
      :title="CVcontrolType == 'add' ? '简历添加' : '导入简历'"
      width="340"
      placement="right"
      :closable="false"
      @close="onClose"
      :visible="CVcontrolType ? true : false"
    >
      <div v-if="CVcontrolType == 'add'">
        <a-form style="padding-bottom:50px;">
          <a-form-item v-bind="formItemLayout" label="姓名">
            <a-input type="text" v-model="CVname" placeholder="请输入姓名" />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="性别">
            <a-radio-group @change="onChange" v-model="CVsex">
              <a-radio :value="1">女</a-radio>
              <a-radio :value="2">男</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="邮箱">
            <a-auto-complete
              :dataSource="CVemail"
              @change="handleChange"
              placeholder="请输入邮箱"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="手机">
            <a-input
              type="text"
              v-model="CVphone"
              placeholder="请输入手机号码"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="面试岗位">
            <a-tree-select
              style="width:100%;"
              showSearch
              :treeData="posts_type_arr"
              :value="CVpost"
              @change="onChangepost"
              treeNodeFilterProp="title"
              placeholder="请选择岗位"
              searchPlaceholder="可以输入位名称"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="现居住地">
            <a-input
              type="text"
              v-model="CVaddress"
              placeholder="请输入现居住地"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="简历来源">
            <a-select v-model="CVsource" placeholder="请选择简历来源">
              <a-select-option
                v-for="item in CVsource_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="期望薪资">
            <a-select v-model="CVsalary" placeholder="请选择期望薪资">
              <a-select-option
                v-for="item in CVsalary_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="学历">
            <a-select v-model="CVeducation" placeholder="请选择学历">
              <a-select-option
                v-for="(item, k) in education_arr"
                :key="k"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="籍贯">
            <a-select v-model="CVnativePlace" placeholder="请选择籍贯">
              <a-select-option
                v-for="(item, k) in CVnativePlace_arr"
                :key="k"
                :value="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="上传照片">
            <a-upload
              :fileList="imgfileList"
              :remove="ImghandleRemove"
              :beforeUpload="imgbeforeUpload"
            >
              <a-button> <a-icon type="upload" />上传照片 </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="上传简历">
            <a-upload
              :fileList="CVfileList"
              :remove="CVhandleRemove"
              :beforeUpload="CVbeforeUpload"
            >
              <a-button> <a-icon type="upload" /> 上传简历 </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
      <div v-else>
        <a-form style="padding-bottom:50px;">
          <a-form-item label="面试岗位">
            <a-tree-select
              showSearch
              style="width:100%;"
              :treeData="posts_type_arr"
              :value="CVpost"
              @change="onChangepost"
              treeNodeFilterProp="title"
              placeholder="请选择岗位"
              searchPlaceholder="可以搜索岗位名称"
            />
          </a-form-item>

          <a-form-item label="简历来源">
            <a-select v-model="CVsource" placeholder="请选择简历来源">
              <a-select-option
                v-for="item in CVsource_arr"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="" style="margin-top: 24px;">
            <a-upload-dragger
              name="file"
              @change="fileDrawerChange"
              :beforeUpload="fileBeforeUpload"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">上传文件或将文件拖入</p>
              <!--  :multiple="true" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" -->
              <p class="ant-upload-hint">
                支持文件格式：.doc,.docx,.txt,.rtf,.pdf,.html,.wps,等文本格式简历全部都支持解析。
              </p>
            </a-upload-dragger>
          </a-form-item>
        </a-form>
      </div>
      <div class="drawer-btns">
        <a-button type="default" class="drawer-btn">取 消</a-button
        ><a-button class="drawer-btn" type="primary">确 定</a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
const columns = [
  {
    title: 'Full Name',
    width: 180,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left'
  },
  { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
  { title: 'Column 1', dataIndex: 'address', key: '1' },
  { title: 'Column 2', dataIndex: 'address', key: '2' },
  { title: 'Column 3', dataIndex: 'address', key: '3' },
  { title: 'Column 4', dataIndex: 'address', key: '4' },
  { title: 'Column 5', dataIndex: 'address', key: '5' },
  { title: 'Column 6', dataIndex: 'address', key: '6' },
  { title: 'Column 7', dataIndex: 'address', key: '7' },
  { title: 'Column 8', dataIndex: 'address', key: '8' },
  {
    title: 'Action',
    key: 'operation',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'action' }
  }
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York Park'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 40,
    address: 'London Park'
  }
]
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
import {
  __dateTransation, //插件时间格式化公共方法
  __persionnal_Status,
  __arr_experience,
  __arr_province,
  __arr_Education,
  __fast_Select
} from '@/assets/js/public_ren'
export default {
  name: 'InterviewManagement',
  data () {
    return {
      SHOW_PARENT,
      //顶部统计
      censusMonth: '',
      censusHR: undefined,
      censusArea: undefined,
      censusCity: undefined,
      censusHR_arr: [], //hr所有
      censusArea_arr: [], //大区
      censusCity_arr: [], //城市
      monthFormat: 'YYYY-MM',
      HRarr: [
        {
          //顶部HR统计
          name: '接收简历',
          value: 200
        },
        { name: '接收简历', value: 130 },
        { name: '接收简历', value: 10 },
        { name: '接收简历', value: 80 },
        { name: '接收简历', value: 56 },
        { name: '接收简历', value: 36 },
        { name: '接收简历', value: 30 },
        { name: '接收简历', value: 20 }
      ],

      spinning: false,
      clientH: '400px',
      //table
      data,
      columns,
      //搜索
      posts_type_arr: [], //岗位arr
      posts_type: [],
      progress_type_arr: [], //简历来源
      progress_type: undefined,
      pressing_type_arr: [], //紧急程度
      pressing_type: undefined,
      needs_type_arr: [], //需求HR
      needs_type: undefined,
      classify_name: '', //input输入框
      startDate: '',
      endDate: '',

      arSel: [9999], //选中的部门
      id: '', //选中的部门Id
      treeData: [
        {
          title: '1级',
          id: '111',
          children: [
            { title: '1.1级', id: '123' },
            { title: '1.2级', id: '124' },
            { title: '1.3级', id: '125' }
          ]
        },
        {
          title: '陈星级',
          id: '222',
          children: [
            { title: '2.1级', id: '223' },
            { title: '2.2级', id: '224' },
            { title: '2.3级', id: '225' }
          ]
        },
        { title: '2.2级', id: 333 },
        { title: '2.3级', id: 444 }
      ],

      page: 5, //每页5条
      index: 1, //页码
      total: 1, //总数
      //简历
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      CVcontrolType: false,
      CVname: '',
      CVsex: 1,
      CVemail: [],
      CVphone: '',
      CVpost: [], //面试岗位(侧边栏)
      CVaddress: '', //现居地
      CVsource_arr: data, //简历来源
      CVsource: undefined, //
      CVsalary_arr: data, //期望薪资
      CVsalary: undefined,
      education_arr: __arr_Education(), //学历
      CVeducation: undefined,
      CVnativePlace_arr: __arr_province(), //籍贯
      CVnativePlace: undefined,
      imgfileList: [
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: 'http://www.baidu.com/xxx.png'
        }
      ], //上传照片
      CVfileList: [
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: 'http://www.baidu.com/xxx.png'
        }
      ] //上传简历
    }
  },
  created () {
    //获取当前月
    let myDate = new Date()
    let year = myDate.getFullYear()
    let month = myDate.getMonth()
    if (month === 0) {
      month = 12
      year = year - 1
    } else if (month < 10) {
      month = '0' + month
    }
    this.censusMonth = year + '-' + month
    console.log(this.censusMonth)
    if (!sessionStorage.getItem('posts_type_arr')) {
      this.$axios.get(8007, {}, res => {
        console.log(res)
        if (res.data.code == 1) {
          this.posts_type_arr = res.data.data
          window.sessionStorage.setItem(
            'posts_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    } else {
      this.posts_type_arr = JSON.parse(
        window.sessionStorage.getItem('posts_type_arr')
      )
    }
  },
  mounted () {
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 248 + 'px'
  },
  methods: {
    onChange (val) {
      console.log('change', val)
      //简历-性别 用
    },
    //email
    handleChange (value) {
      this.CVemail =
        !value || value.indexOf('@') >= 0
          ? []
          : [`${value}@gmail.com`, `${value}@163.com`, `${value}@qq.com`]
    },
    moment,
    //月份
    monthChange (val) {
      console.log(val)
      let census_month = new Date(val._d)
      let month =
        census_month.getFullYear() +
        '-' +
        (census_month.getMonth() + 1 < 10
          ? '0' + (census_month.getMonth() + 1)
          : census_month.getMonth() + 1)
      console.log(month)
      this.censusMonth = month
    },
    //侧边树选项
    openclick (obj) {
      //打开与收起
      let num
      if (this.arSel.length > 0) {
        for (let i = 0; i < this.arSel.length; i++) {
          console.log(obj.id, this.arSel[i])
          if (obj.id == this.arSel[i]) {
            num = i
          }
        }
        console.log(num)
        num ? this.arSel.splice(num, 1) : this.arSel.push(obj.id)
        console.log(this.arSel)
      } else {
        this.arSel.push(obj.id)
      }
      console.log(this.arSel)
    },
    //选中
    selectFn (obj) {
      console.log(obj)
      this.id = obj.id
    },
    //选择岗位(搜索栏)
    onChangeTree (value) {
      console.log(value)
      this.posts_type = value
    },
    //选择岗位(侧边栏)
    onChangepost (value) {
      this.CVpost = value
    },
    //搜索时间
    changeTime (value) {
      this.startDate = value[0]._d
      this.endDate = value[1]._d
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },
    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn(
        this.index,
        this.page,
        this.progress_type,
        this.needs_type,
        this.posts_type,
        this.pressing_type,
        this.selectVal,
        this.startDate,
        this.endDate
      )
    },
    //重置
    resetBtn () {
      console.log(
        this.posts_type,
        this.needs_type,
        this.progress_type,
        this.pressing_type
      )
      this.progress_type = undefined
      this.needs_type = undefined
      this.posts_type = ''
      this.pressing_type = undefined
      this.departmentSel = []
      this.classify_name = ''
      this.startDate = ''
      this.endDate = ''
      this.index = 1
      // this.tableFn(1,this.page,this.classify_type,this.classify_name,this.startDate,this.endDate);
    },
    //搜索按钮
    serachBtn () {
      if (
        !this.classify_type &&
        !this.classify_name &&
        !this.startDate &&
        !this.endDate
      ) {
        console.log('都为空不请求!')
        return false
      }
      this.tableFn(
        1,
        this.classify_type,
        this.classify_name,
        this.startDate,
        this.endDate
      )
    },
    // 点击table
    rowClick (record, index) {
      let that = this
      return {
        on: {
          click: () => {
            this.$router.push({
              path: '/personinfo/personManagement',
              query: {
                id: '5d37238944ba905e9c37a7045e4653f4'
              }
            })
            console.log(record, index)
          }
        }
      }
    },
    //停止招聘
    stopFn (val) {
      console.log(val)
    },
    delFn (val) {
      console.log(val)
    },
    //请求ajax
    tableFn (
      index = 1,
      progress_type = '',
      needs_type = '',
      posts_type = '',
      pressing_type = '',
      selectVal = '',
      startDate = '',
      endDate = ''
    ) {
      console.log(
        index,
        progress_type,
        needs_type,
        posts_type,
        pressing_type,
        selectVal,
        startDate,
        endDate
      )
    },

    //简历导入 引入的侧边栏控制
    CVctrlFn (type) {
      this.CVcontrolType = type
    },
    onClose () {
      //简历导入 引入的侧边栏隐藏
      this.CVcontrolType = false
    },
    //拖动简历上传
    fileDrawerChange (info) {
      console.log(info)
      const status = info.file.status
      // if (status !== 'uploading') {
      // console.log(info.file, info.fileList);
      // }
      // if (status === 'done') {
      // this.$message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      // this.$message.error(`${info.file.name} file upload failed.`);
      // }
    },
    fileBeforeUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg'
      return false
    },
    //简历上传
    CVhandleRemove (file) {
      console.log(file)
      let index = this.CVfileList.indexOf(file)
      let newFileList = this.CVfileList.slice()
      newFileList.splice(index, 1)
      this.CVfileList = newFileList
      console.log(this.CVfileList)
    },
    // 照片上传
    ImghandleRemove (file) {
      console.log(file)
      let index = this.imgfileList.indexOf(file)
      let newFileList = this.imgfileList.slice()
      newFileList.splice(index, 1)
      this.imgfileList = newFileList
      console.log(this.imgfileList)
      // return __uploadFile__(file) 文件上传
    },
    imgbeforeUpload (file) {
      console.log(file)
      this.imgfileList = [...this.imgfileList, file]
      return false
    },
    CVbeforeUpload (file) {
      console.log(file)
      this.CVfileList = [...this.CVfileList, file]
      return false
    }
  }
}
</script>
<style lang="less" scoped>
.InterviewManagement {
  margin-top: 21px;
  a {
    text-decoration: none;
  }
  .InterviewManagement-content {
    margin-left: 10px;
    width: 100%;
    .serach_box {
      position: relative;

      .map-position {
        position: absolute;
        top: 4px;
        right: 0px;
      }
      .item-label {
        width: 160px;
      }
    }
  }
  .item-label {
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .tree-li {
    padding-left: 10px;
    height: 36px;
    line-height: 36px;
  }
}

.ant-layout-sider-dark {
  flex: 0 0 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
  width: 140px !important;
}
.step-circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-width: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  columns: #333333;
  font-size: 18px;
  font-weight: bold;
}
.step-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  .liner-div {
    height: 1px;
    border: 1px solid #ebebeb;
    position: absolute;
    width: 89%;
    top: 26px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
  .step-item {
    width: 100%;
    text-align: center;
    background-color: transparent;
    z-index: 10;
    .step-title {
      margin-bottom: -4px;
      margin-top: 24px;
    }
  }
}

.ant-form-item {
  margin-bottom: 14px;
}
</style>
